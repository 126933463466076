:root {
  --main-green-1: #00ccaa;
  --main-green-2: #ebfaf7;
  --main-green-3: #b4efe6;
  --main-green-4: #5ee1cb;
  --main-green-5: #00b195;
  --main-green-6: #00a88d;
  --main-light-green-1: #e8fcf8;
  --main-grey-1: #777777;
  --main-grey-2: #cbcbcb;
  --main-grey-2-lighter: #e8e8e8;
  --main-grey-3: #f1f1f1;
  --main-grey-4: #dadada;
  --main-light-grey-1: #fafafa;
  --main-black-1: #000000;
  --main-black-2: #353f4b;
  --main-yellow: #daa920;
  --main-yellow-1: #ffe96e;
  --main-blue: #26aae1;
  --main-blue-1: #5a6978;
  --main-blue-2: #f4f7fc;
  --main-blue-3: #e9f1fd;
  --main-blue-4: #d9e6fd;
  --main-blue-5: #3b63a2;
  --main-blue-6: #27aae1;
  --main-blue-lighter: #9bcdff;
  --main-red: #ee7374;
  --main-red-1: #f37173;
  --main-red-2: #ef4346;
  --main-red-lighter: #fff0f0;
  --main-violet: #6f60aa;
  --main-blue-green: #e8f7fa;
  --main-orange: #f58345;
}
