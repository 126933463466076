td.structure-description-item {
  .ant-select {
    max-width: 30vw;

    @media screen and (min-width: 1200px) {
      max-width: 40vw;
    }

    @media screen and (min-width: 1500px) {
      max-width: 50vw;
    }
  }

  input {
    max-width: 30vw;

    @media screen and (min-width: 1200px) {
      max-width: 40vw;
    }

    @media screen and (min-width: 1500px) {
      max-width: 50vw;
    }
  }
}
