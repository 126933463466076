.editor {
  border: 1px solid var(--main-grey-2-lighter);
  border-radius: 3px;
  width: 100%;
  padding: 10px;
  background-color: white;

  .codex-editor__redactor {
    padding-bottom: 75px !important;
  }

  .ce-block__content {
    max-width: 100% !important;
    margin-right: 50px;
  }

  .ce-toolbar {
    left: 100% !important;
  }
}
