.ant-input-number-handler-wrap {
  opacity: 100 !important;
}

.ant-input-number-input-wrap {
  padding-right: 18px;
}

.ant-input-number-input-wrap > input.ant-input-number-input {
  text-align: right;
}
