.loader {
  margin-top: 1%;
  opacity: 0;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: 0.15s fadein both;

  & > .wrapper {
    position: relative;
    height: fit-content;
    width: fit-content;

    & > img,
    & > .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: cover;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
