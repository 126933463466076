h1.app-metier-title {
  font-weight: bold;
}

h2.app-metier-title {
  font-weight: bold;
  color: var(--main-green-1);
}

h3.app-metier-title {
}

h4.app-metier-title {
  color: var(--main-green-1);
}

h5.app-metier-title {
}
