.scroll-to-top {
  position: relative;

  .scroll-to-top-btn {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
    animation: fade-in 2s;

    &.hidden {
      opacity: 0;
      animation: fade-out 1s;
    }
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
