.presentation {
  &.presentation-header {
    background-color: var(--main-green-4);
    margin-bottom: 1.5em;
  }

  h5 {
    margin: 1em;
    color: white;
  }

  h4 {
    margin-bottom: 1em;
  }

  .grey-spans {
    span {
      color: var(--main-grey-1);
    }
  }

  .inner-header {
    min-height: 175px;
    padding: 1.5em;

    &.inner-header-projet {
      background-color: var(--main-blue-2);
    }

    &.inner-header-parcours {
      background-color: var(--main-blue-3);
    }

    &.inner-header-suivi {
      background-color: var(--main-green-1);
    }
  }

  .presentation-loc {
    background-color: var(--main-green-4);

    h4 {
      margin-top: 1em;
      color: white;
    }

    .white-spans {
      span {
        color: white;
      }
    }
  }
}
