@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,900");
//@import url('https://fonts.googleapis.com/css?family=Zilla+Slab:400,700');
@import "./fonts";
@import "./colors";

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #f4f7fc;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content {
  //Corresponds to NAV_BAR_HEIGHT in NavBar.tsx
  margin-top: 65px;
}

.text-highlight-color {
  color: var(--main-green-1);
}

.ant-drawer {
  position: initial;

  .ant-drawer-mask {
    display: none;
  }
}

.breadcrumb {
  margin-bottom: 1rem;
}

.result-row {
  background-color: var(--main-grey-3) !important;
  font-weight: var(--bold);

  &:hover > td {
    background-color: var(--main-grey-3) !important;
  }

  .previous-column {
    background-color: var(--main-grey-3) !important;
  }

  .intermediary-column {
    background-color: var(--main-grey-3) !important;
  }
}

.previous-column {
  background-color: var(--main-green-2);
}

.intermediary-column {
  color: var(--main-violet);
  background-color: var(--main-blue-3);
}

.table-grey-last-row tbody > tr {
  &:last-child > td {
    background-color: #f0f0f0 !important;
    font-weight: var(--bold);
  }
}

.table-blue-last-row tbody > tr {
  &:last-child > td {
    background-color: var(--main-blue-1) !important;
    color: white;
    font-weight: var(--bold);

    > span {
      color: white;
    }
  }
}

.result-row {
  background-color: var(--main-grey-3) !important;
  font-weight: var(--bold);

  & > td.ant-table-cell-row-hover {
    background: var(--main-grey-3) !important;
  }

  .previous-column {
    background-color: var(--main-grey-3) !important;
  }

  .intermediary-column {
    background-color: var(--main-grey-3) !important;
  }
}

.highlighted {
  background-color: var(--main-blue-1) !important;

  & > td span {
    font-weight: var(--bold);
    color: white !important;
  }

  & > td.ant-table-cell-row-hover {
    background: var(--main-blue-1) !important;
  }

  .previous-column {
    background-color: var(--main-blue-1) !important;
  }

  .intermediary-column {
    background-color: var(--main-blue-1) !important;
  }
}

.parent-table {
  table {
    tbody {
      .ant-table-expanded-row.ant-table-expanded-row-level-1 {
        > td {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

.nested-table > div > div > div {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ant-table-summary {
  background-color: var(--main-grey-3) !important;

  & > tr {
    font-weight: var(--bold);

    &.highlight {
      background-color: var(--main-blue-1);
      color: white;
    }
  }
}

.table-summary-cell {
  vertical-align: bottom;

  &.center {
    text-align: center;
  }

  &.end {
    text-align: end;
  }

  &.end-with-padding {
    text-align: end;
    padding-right: 44px !important;
  }
}

.ant-popover-inner-content {
  white-space: nowrap;
}

.pointer-cursor {
  cursor: pointer;
}

th.inner-column-title {
  font-size: 0.7em;
}

td.inner-column-text {
  font-size: 0.8em;
}

.ant-space.full-width-space {
  width: 100%;
}

.react-query-message {
  span {
    margin-inline-end: 0 !important;
  }
}
